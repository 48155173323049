import Layout from 'layouts';

const Error404 = () => {
  return <div>This page does not exist.</div>;
};

Error404.getLayout = (page: React.ReactElement) => {
  return <Layout>{page}</Layout>;
};
export default Error404;
